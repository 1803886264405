import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["photos"];

  connect() {
    this.photosPreview();
  }

  photosPreview() {
    this.photosTargets.forEach(photo => {
      photo.addEventListener('mouseenter', this.showPreview);
      photo.addEventListener('mouseleave', this.hidePreview);
    });
  }

  showPreview(event) {
    const photo = event.currentTarget;
    const previewImage = document.createElement('img');

    previewImage.classList.add('preview-image');
    previewImage.src = photo.dataset.preview;
    document.body.appendChild(previewImage);

    previewImage.offsetHeight; // Applies css
    photo.hoverImage = previewImage;
  }

  hidePreview(event) {
    const photo = event.currentTarget;
    if (photo.hoverImage) {
      document.body.removeChild(photo.hoverImage);
      photo.hoverImage = null;
    }
  }
}
